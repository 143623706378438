.chip {
  --chip-text-color: var(--color-white, #fff);
  --chip-bg-color: var(--main-blue, #1c50de);
  --chip-border-color: var(--main-blue, #1c50de);

  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--indent-xxs, 4px) var(--indent-xs, 8px);
  border-radius: 4px;
}

.filed {
  color: var(--chip-text-color);
  background-color: var(--chip-bg-color);
  border-color: var(--chip-bg-color);
}

.outlined {
  color: var(--chip-text-color);
  background-color: transparent;
  border-color: var(--chip-bg-color);
}

.text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
