.modal {
  width: 530px;
}

.content {
  display: flex;
  flex-direction: column;
  gap: var(--indent-s);
}

.content p:not(:last-child) {
  margin-bottom: var(--indent-s);
}

.content p:last-child {
  margin-bottom: 0;
}
