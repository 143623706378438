.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 474px;
}

.content {
  flex-grow: 1;
}
