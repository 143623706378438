.header {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.listBlocks {
  margin: 0;
  list-style: disc;
  padding-left: var(--indent-m);
}

.listBlocks > li {
  position: relative;
}

.listBlocks > li::before {
  color: var(--color-gray-800);
  content: '\2022';
  font-size: 19px;
  font-weight: 700;
  left: -15px;
  line-height: 10px;
  position: absolute;
  top: 3px;
}

.infoBlock {
  background: var(--color-blue-50);
  align-items: center;
  display: flex;
  gap: 8px;
  padding: 8px;
  border-radius: 4px;
}

.infoBlockPrivate {
  align-items: initial;
}

.headerTitle {
  margin: 8px 0;
}

.icon {
  flex-shrink: 0;
}

.icon path {
  stroke: var(--color-blue-300);
}

.infoText {
  color: var(--color-gray-800);
}

.infoTextBold {
  font-weight: 700;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}

.inputs {
  margin-bottom: -20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
}

.isHidden {
  overflow: hidden;
}

.emailRequestInput {
  width: 100%;
  position: relative;
}

.error {
  position: absolute;
  left: 0;
  bottom: -15px;
  color: var(--color-red-error);
}

.modal {
  max-width: 600px;
}
