.buttonWrapper {
  display: inline-block;
}

.button {
  background: transparent;
  color: #bdbec3;
  font-size: 16px;
  border: 0;
  padding-top: 4px;
  vertical-align: bottom;
  height: 28px;
  width: 30px;
}

.button svg {
  fill: #bdbec3;
  width: 20px;
  height: 20px;
}

.button:hover,
.button:focus {
  outline: 0; /* reset for :focus */
}

.active {
  color: #6f83e7;
}

.active svg {
  fill: #6f83e7;
}

blockquote {
  font-size: var(--main-text-size);
}
