.infoBlock,
.account,
.bic,
.logoDescBlock {
  margin-bottom: var(--indent-ml);
}

.title,
.fullWidthInput:not(:last-child),
.country,
.region {
  margin-bottom: var(--indent-s);
}

.account,
.bic {
  width: calc(50% - 6px);
  display: inline-block;
}

.country,
.region {
  width: calc(50% - 6px);
  display: inline-block;
}

.country div,
.region div {
  width: 100%;
}

.bic,
.region {
  margin-left: var(--indent-s);
}

.logoDescBlock {
  width: 100%;
  display: flex;
  gap: var(--indent-s);
}

.description {
  resize: none;
}

.accBicContainer {
  width: 100%;
  height: 73px;
  display: flex;
  gap: var(--indent-s);
}
