.btn {
  padding: var(--padding-xs);
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  cursor: pointer;
}

.btn:hover {
  background: rgb(17 22 147 / 6%);
  border-radius: 4px;
}
