.cell {
  word-break: break-all;
}

.cell.disabled,
.disabled {
  background-color: var(--color-gray-200) !important;
  color: var(--color-gray-700);
}

.cell.error {
  color: var(--color-additionaly-cardinal);
}

.cell.right input {
  text-align: right;
}

.cell input:focus {
  border: inherit !important;
}

.maskedInput:not(.error) fieldset {
  border-color: #f2f4f8 !important;
}

.maskedInput fieldset {
  border-radius: 2px !important;
}

.maskedInput input:active ~ fieldset {
  border-color: var(--color-blue-400) !important;
}

.maskedInput input:focus ~ fieldset {
  border-color: var(--color-blue-400) !important;
}

.maskedInput input:hover ~ fieldset {
  border-color: var(--color-blue-200) !important;
}

.maskedInput div {
  border-spacing: initial !important;
  height: 100%;
}

.productName {
  height: 100%;
}

.productTable {
  padding-bottom: var(--indent-ml);
  width: 100%;
}

.external {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--indent-xxs);
}

.fillInManually {
  display: flex;
  align-items: center;
}

.customerActions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--indent-xxs);
}

.zeroPrice path {
  stroke: var(--secondary-yellow);
}

.editModeCTA {
  display: flex;
  align-items: center;
  gap: var(--indent-l);
  margin-bottom: var(--indent-xxs);
}

.noWrap {
  white-space: nowrap;
}
