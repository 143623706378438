.text {
  color: var(--main-dark-gray);
}

.divider {
  border-color: var(--divider-color);
  margin: var(--indent-xs) 0;
}

.actions {
  display: flex;
}

.right {
  margin-left: auto;
}
