.navigation {
  width: 220px;
  flex-grow: 1;
  padding-top: 24px;
  overflow-x: hidden;
  overflow-y: auto;

  &__service {
    display: flex;
    width: 100%;
    flex-direction: column;
  }

  &__service-list {
    margin: 0;
    padding: 0 16px;
    list-style: none;
    width: 100%;
  }

  &__service-list_collapsed {
    padding: 0 0 0 16px;
  }

  &__section {
    margin: 0 0 20px 0;
    display: flex;
    flex-direction: column;

    &-header {
      display: flex;
      align-items: center;
    }

    &-header_collapsed {
      position: relative;
    }

    &-header-icon {
      margin: 0 9px 0 0;
    }

    &-header-icon_collapsed {
      margin: 0;
    }

    &-header-name {
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: #252B49;
      text-transform: uppercase;
      cursor: pointer;
      user-select: none; 
    }

    &-header-name_switcher {
      font-weight: 400;
      color: #767b92;
      text-transform: none;
    }

    &-header-name_switcherHide {
      display: none;
    }

    &-header-toogler {
      margin: 0 0 0 9px;
      cursor: pointer;
      user-select: none;
    }

    &_gray {
      background: #F2F4F8;
      padding 5px;
      cursor: pointer;
    }

    &_collapsed {
      margin: 0 0 20px -5px;
    }
  }

  &__section-name {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
    color: #252b49;
    padding-left: 9px
  }

  &__item-link {
    margin-top: 3px

    &:first-child {
      margin-top: 0
    }
  }

  &__list-of-links {
    list-style: none;
    padding: 10px 0 0 0;
    margin-bottom: 0;
  }

  &__link {
    font-size: 14px;
    font-weight: 400;
    color: #767b92;
    padding-left: 24px
    padding-right: 15px
    border-left: 2px solid transparent
    display: flex;
    align-items: center;

    &:hover {
      color: #163881
    }

    &_active {
      color: #1c50de
      //border-left-color: #1c50de

      &:hover {
        color: #1c50de
      }
    }
  }

  &__badge {
    width: 22px;
    height: 13px;
    display: inline-flex;
    justify-content: center;
    font-size: 10px;
    font-weight: 500;
    color: #ffffff;
    border-radius: 45px;
    background-color: #fd4f53;
    margin-left: auto;
    margin-right: -12px;
    opacity: 0;

    &_show {
      opacity: 1;
    }

    &_collapsed {
      position: absolute;
      border-radius: 10px;
      width: 20px;
      height: 20px;
      font-size: 9px;
      right: 3px;
      top: -10px;
      border: 2px solid white;
      display: flex;
      align-items: center;
      margin: 0;
}
  }
}
