$border-color = #c6cae3
$btn-width = 17px

.input-number {
  display: inline-block
  width: 74px
  height: 26px
  border-radius: 4px
  border: 1px solid $border-color
  font-size: 14px
  position: relative
  background-color: #fff

  &_disabled {
    background-color: #eee
  }

  &_size {
    &_m {
      height: 26px
    }

    &_l {
      height: 36px
    }
  }

  &__input {
    width: 100%
    height: inherit
    font-size: inherit
    background-color: transparent
    border: none
    outline: none
    padding: 0
    padding-left: 5px
    padding-right: $btn-width + 6px
    text-overflow: ellipsis;
  }

  &__btn {
    position: absolute
    right: 0
    width: $btn-width
    height: 50%
    border-left: 1px solid $border-color
    display: flex
    justify-content: center
    align-items: center
    padding-right: 1px
    cursor: pointer
    user-select: none

    &:hover path {
      fill: #252b49
    }

    &_increase {
      top: 0
      padding-top: 1px
    }

    &_decrease {
      border-top: 1px solid $border-color
      bottom: 0
      padding-bottom: 1px
    }
  }
}
