.textButtons {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  padding: 12px;
}

.fullButtonsWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-height: var(--cw--messenger-height);
  min-height: var(--cw--messenger-height);
}
