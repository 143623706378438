.inputErrorHint {
  color: var(--color-red-error);
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: var(--indent-m);
}

.title {
  color: var(--color-gray-800);
}

.inputControl {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 2px;
}

.contactInfo {
  display: flex;
  width: 100%;
  gap: var(--indent-ml);
  align-items: flex-start;
}

.emailWrapper {
  width: 100%;
  max-width: 344px;
}

.userInfo {
  display: flex;
  width: 100%;
  gap: var(--indent-ml);
  align-items: flex-start;
}

.userInfoInputs {
  display: flex;
  flex-direction: column;
  gap: var(--indent-m);
  width: 100%;
  max-width: 344px;
}

.fileSelect {
  margin-top: 16px;
}

.divider {
  width: 100%;
  background-color: var(--color-gray-300);
  height: 1px;
}
