.modal-container {
  &__radio-btn {
    width: 12px;
    height: 12px;
    display: inline-block;
    border-radius: 50%;
    border: 1px solid #c6cae3;
    cursor: pointer;
    margin-right: 6px;
    position: relative;
    flex-shrink: 0;

    &:after {
      content: '';
      position absolute;
      border-radius: 50%;
      width: 6px;
      height: 6px;
      top: 2px;
      left: 2px;
    }

    &_set {
      border: 1px solid #bbb;

      &:after {
        background-color: #000;
      }
    }
  }
}

.select-label {
  margin-bottom: 5px;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #767b92;
}

.help-modal-first-page {
  background-image: url("/img/help/help-background.png");
  background-size: 100% 100%;
  height: 654px;
}

.modal-header-first-page-text-block {
  margin-left: 50px;
  margin-top: 160px;
}

.modal-header-first-page-title {
  font-size: 36px;
}

.modal-header-first-page-text {
  margin-top: 90px;
  font-size: 20px;
  width: 500px;
}

.modal-header-first-page-next-button {
  margin-top: 90px;
  font-size: 22px;
  width: 190px;
  height: 45px;
}

.help-modal-first-page>.modal-header {
  background-color: transparent;
}

.help-modal-title {
  margin-bottom: 20px;
  font-size: 22px;
  font-weight: 500;
}

.help-modal-img {
  width: 100%;
  object-fit: contain;
  box-shadow: 1px 1px 3px grey;
  margin-left: 11px;
}

.help-modal>.modal-content>div>.modal-body {
  padding-top: 0;
}

.help-modal-text {
  width: 100%;
  height: 60px;
  font-size: 14px;
  white-space: pre-line;
  margin-left: 11px;
}
.help-modal-double-img-container {
  width: 100%;
  height: 440px
  margin-left: 11px;
}

.help-modal-double-img-1 {
  position: absolute;
  left: 305px;
  top: 212px;
  z-index: 1;
  width: 62%;
  box-shadow: 1px 1px 3px grey;
}

.help-modal-double-img-2 {
  position: absolute;
  box-shadow: 1px 1px 3px grey;
  width: 70%;
}

.help-modal.modal-body {
  padding-top: 0px;
}

@media screen and (max-height: 650px) {
  .help-modal {
    width: 600px;
  }

  .help-modal-first-page {
    height: 495px;
  }

  .modal-header-first-page-text-block {
    margin-top: 72px;
  }

  .modal-header-first-page-title {
    width: 350px;
    font-size: 28px;
  }

  .modal-header-first-page-text {
    margin-top: 45px;
    width: 300px;
    font-size: 17px;
  }
  .modal-header-first-page-next-button {
    margin-top: 45px;
  }

  .help-modal-double-img-container {
    height: 278px;
  }

  .help-modal-double-img-1 {
    left: 222px;
    top: 116px;
    width: 56%;
  }

  .help-modal-double-img-2 {
    width: 70%;
  }

  .help-modal-text {
    font-size: 12px;
  }
}

#popover-helper>.arrow {
  display: none;
}

.help-link-more {
  margin-top: 10px;
}

.help-text-popover {
  margin-top: 10px;
}

#popover-helper {
  margin-left: 140px;
}

.modal-create-group-chat__text-error {
  font-size: 12px;
  text-align: left;
  width: 100%;
}

.no-internet-connection-modal-backdrop + .fade.in.modal {
  display: flex!important;
}

.no-internet-connection-modal-backdrop {
  opacity: 0!important;
}

.modal-window {
  width: 600px;
  background-color: #fff;
  box-shadow: 0 5px 15px #00000080;
  border: 1px solid rgba(0,0,0,0.65);
  border-radius: 6px;
  position: absolute;
  margin-top: 30px;
  outline: 0;
}
