.ModalContent {
  max-width: 520px;
}

.linkToRoutes {
  color: var(--color-blue-500);
}

.needText {
  font-weight: var(--text-bold);
}
