.switcher {
  padding: 0;
  cursor: pointer;
  width: 100%;
  height: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--color-white);
  border: 1px solid var(--color-gray-500);
  border-radius: 4px;
  color: var(--color-gray-900);
}

.switcher:hover {
  background: var(--color-gray-200);
}

.switcher:active {
  color: var(--color-white);
  background: var(--color-blue-300);
}

.active {
  color: var(--color-white);
  background: var(--color-blue-300);
}

.active:hover {
  color: var(--color-white);
  background: var(--color-blue-300);
}
