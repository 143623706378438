.formUsrleContainer {
  padding: var(--indent-xxs) var(--indent-xs);
  display: flex;
  align-items: center;
  gap: var(--indent-s);
}

.formUsrleContent {
  color: var(--color-gray-800);
  display: flex;
  flex-direction: column;
  gap: 2px;
  max-width: 282px;
  width: 100%;
}

.highlight {
  padding: 0;
  color: var(--color-blue-300);
  background-color: transparent;
  font-weight: var(--text-bold);
}

.textBold {
  white-space: initial;
  color: var(--color-gray-800);
  text-transform: uppercase;
}

.textDefault {
  white-space: initial;
  color: var(--color-gray-600);
}

.noResult {
  color: var(--color-gray-700);
  white-space: pre-wrap;
}

.errorMessage {
  color: var(--color-red-error);
  white-space: pre-wrap;
}

.label {
  display: flex;
  flex-direction: row;
  gap: 4px;
  font-size: 12px;
  color: #767b92;

  /* Todo при редизайне всей формы применить нормальный цвет */
}

.redStar {
  color: var(--color-red-error);
}

/* TODO удалить когда бедет редизайн */
.typeahead {
  border-color: #ebeef4 !important;
}

.typeahead input::placeholder {
  color: #c6cde7 !important;
}
