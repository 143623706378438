.checkbox {
  margin: 0 !important;
  position: relative;
  width: 16px;
  height: 16px;
  background: var(--color-white);
  border: 1px solid var(--color-gray-500);
  border-radius: 4px;
  appearance: none;
  outline: 0;
  cursor: pointer;
  transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);
}

.checkbox::before {
  position: relative;
  content: '';
  display: block;
  top: 1px;
  left: 5px;
  width: 5px;
  height: 10px;
  border-style: solid;
  border-color: var(--color-white);
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  opacity: 0;
}

.checkbox:checked {
  color: var(--color-white);
  background: var(--color-blue-900);
}

.checkbox:checked::before {
  opacity: 1;
}

.checkbox:focus {
  outline-offset: 0 !important;
}

.checkbox:disabled {
  border: none;
  background: var(--color-gray-400-hover-element);
}
