.search-block {
  font-size: 14px;

  &__search {
    width: 300px;
    position: relative;
    overflow: hidden;
  }

  &__input {
    width: 100%
    height: 36px;
    border-radius: 18px;
    background-color: #ffffff;
    border: 1px solid #c6cae3;
    outline: none;
    padding-left: 14px;
    padding-right: 62px;

    &::placeholder {
      color: #c6cae3;
    }

    &_active {
      border-color: #767b92;
    }
  }

  &__btn {
    height: 36px;
    width: 36px;
    padding: 0;
    line-height: 0;
    border: none;
    background-color: transparent;
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0.4;
    outline: none;
    justify-content: center;
    align-items: center;

    &_show {
      display: flex;
    }

    &_left {
      right: 26px;
    }

    &:hover {
      opacity: 1
    }
  }
}

.search-block_theme_main {
  .search-block__input {
    border-color: #EBEEF4;
    height: 34px;
  }

  .search-block__btn {
    width: 34px;
    height: 34px;
  }
}
