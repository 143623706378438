.container {
  display: flex;
  flex-direction: column;
  gap: var(--indent-s);
}

.titleBlock {
  width: 100%;
  display: flex;
  align-items: center;
  gap: var(--indent-xs);
}

.title {
  color: var(--main-dark-gray);
}

.externalButton {
  display: flex;
  align-items: flex-end;
}

.infoRow {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  gap: var(--indent-s);
}

.propertyLabel {
  color: var(--main-dark-gray);
}

.propertyValue {
  display: flex;
  gap: var(--indent-s);
  color: var(--secondary-dark-gray);
  word-break: break-word;
}

.propertyHtml {
  color: var(--secondary-dark-gray);
  word-break: break-word;
}
