.footer {
  display: flex;
  align-items: center;
  justify-content: right;
  column-gap: var(--indent-m);
}

.footer.withHint {
  justify-content: space-between;
}

.actions {
  display: flex;
  column-gap: var(--indent-m);
}

.hint {
  color: var(--color-gray-800);
}
