.actions {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.rightActions {
  display: flex;
  gap: var(--indent-m);
}
