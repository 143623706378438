.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: var(--indent-m);
}

.title {
  color: var(--color-gray-800);
}

.inputControl {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 2px;
}
