.modal {
  width: 100%;
  padding: 34px;
  height: 550px;
}

.cross {
  padding: 10px;
  background-color: #f2f4f8;
  border-radius: 0 4px;
  cursor: pointer;
  user-select: none;
  position: absolute;
  top: 0;
  right: 0;
}

.content {
  display: flex;
  flex-direction: column;
  row-gap: var(--indent-s, 12px);
  height: 100%;
}

.title {
  position: relative;
  z-index: 10;
  height: 34px;
  display: flex;
  align-items: center;
  column-gap: var(--indent-xs, 8px);
}

.searchInput {
}

.errorWrapper {
  margin-top: var(--indent-xs, 8px);
}

.table {
  height: 390px;
  width: 100%;
}
