.reg-label {
  font-weight: normal;
  font-size: 14px;
  text-align: left !important;
  width: 180px;
}

.login-label {
  font-size: 18px;
  color: #252b49;
  font-weight: normal;
  margin-top: 45px;
  margin-bottom: 25px;
}

.login-label_theme_desktop {
  margin-left: 50px;
}

.login-label_theme_mobile {
  margin-left: 30px;
}

.reg-input {
  width: 340px;
}

.title-registration {
  font-size: 20px !important;
  font-weight: normal !important;
}

.form-group-login {
  margin-top: 0px
  height: 75px
  margin-bottom: 2px
}

.form-group-login_theme_desktop {
  margin-left: 50px !important
  width: 307px !important
}

.form-group-login_theme_mobile {
  margin-left: 30px !important
  width: 257px !important
}

.form-group-reg {
  margin-top: 0px
  min-height: 60px
  margin-bottom: 10px
  position: relative;
}

.form-group-reg-eye {
  position: absolute;
  top: 21px;
  right: 0;
}

.form-group-reg_theme_desktop {
  margin-left: 50px !important
  width: 307px !important
}

.form-group-reg_theme_mobile {
  margin-left: 30px !important
  width: 257px !important
}

.control-label-login {
  font-size: 12px
  font-weight: normal
  color: #323854
}

.buttons-login {
  display: block
  width: 187px
  height: 42px
  margin-top: 15px
  margin-left: auto
  margin-right: auto
}

.default-right-form {
  border-radius: 4px
  height: 420px;
  background-color: #fff
  box-shadow: 0px 6px 10px rgba(118, 123, 146, 0.2);
  border-radius: 4px;
  margin-left: auto;
  margin-right: auto;
}

.default-right-form_theme_desktop {
  width: 400px
}

.default-right-form_theme_mobile {
  max-width: 320px;
}

.basic-container {
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
}

.basic-container_theme_desktop {
  width: 820px;
}

.basic-container_theme_mobile .logo-link {
  width: 276px;
  height: 57px;
}

.default-form {
  display: block;
}

.form-registation-link {
  color: #767b92
  font-weight: 500
  font-size: 14px
  margin-top: 8px !important
  position: absolute
  text-decoration: none !important
}

.special-antibot-input {
  left: -9999px!important;
  border: 0 none !important;
  font-size: 0 !important;
  height: 0 !important;
  line-height: 0 !important;
  padding: 0 !important;
  position: absolute !important;
  right: 0 !important;
  top: -1000px !important;
  width: 0 !important;
}

.profile-checkbox-label {
  display: inline-block;
  vertical-align: middle;
  text-transform: capitalize;
}

.messenger-title {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #767B92;
  margin-top: 30px;
  margin-bottom: 30px;
}

.reset-pass-link {
  color: #767b92;
  display: inline-block;
  margin: 2px 0 5px;
  text-decoration: none;
  margin-top: 7px;
  font-size: 12px;
  font-weight: 500;
}

.login-controls {
  margin-top: 30px;
}

.login-controls_theme_desktop {
  width: 307px;
  margin-left: 50px;
}

.login-controls_theme_mobile {
  width: 257px;
  margin-left: 35px;
}

.controls-reg-link {
  text-align: center;
  margin-top: 25px;
}

.text-error {
  color: red;
  text-align: center;
}

.page-link {
  line-height: 36px;
  display: inline-block;
  text-decoration: none;
  color: #767b92;
  font-size: 14px;
  font-weight: 500;
}

.logo-link {
  display: block;
  background: url("/img/chat-widget/logo.svg") no-repeat;
  background-position: center center;
  background-size: cover;
  object-fit: contain;
  width: 336px;
  height: 70px;
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
}

.reset-text {
  text-align: center;
  width: 283px;
  font-size: 14px;
  color: #252b49;
  margin-left: 59px;
  margin-top: 24px;
}

.recovery-text {
  font-size: 18px;
  color: #252b49;
  font-weight: normal;
  margin-top: 61px;
}

.recovery-text_theme_desktop {
  margin-left: 50px;
}

.recovery-text_theme_mobile {
  margin-left: 30px;
}

.link-main-wrap {
  text-align: center;
  width: 283px;
  margin-left: 59px;
  margin-top: 30px;
}

.link-main {
  text-decoration: none;
  color: #767b92;
  font-size: 14px;
  font-weight: 500;
}

.small-form {
  margin-top: 55px;
  background-color: #fff;
  height: 306px;
  width: 400px;
  border-radius: 4px;
  box-shadow: 0 6px 10px 0 rgba(118, 123, 146, 0.2);
  margin-left: auto;
  margin-right: auto;
}

.reg-wrap {
  display: inline-block;
  width: 700px;
}

.reg-text {
  font-size: 18px;
  padding-bottom: 5px;
}

.reg-text_theme_desktop {
  margin-left: 50px;
  margin-top: 67px;
}

.reg-text_theme_mobile {
  margin-left: 30px;
  margin-top: 47px;
}

.reg-text-ok {
  margin-top: 110px;
  font-size: 18px;
  padding-bottom: 5px;
  display: flex;
  justify-content: center;
}

.reg-text-success {
  text-align: center;
  width: 283px;
  font-size: 14px;
  color: #252b49;
  margin-top: 24px;
  margin-left: auto;
  margin-right: auto;
}

.reg-text-email {
  text-align: center;
  width: 283px;
  font-size: 14px;
  color: #252b49;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
}
