.cw-dashboard-common {
  width: 100%;
  height: var(--cw-header-height);
  position: relative;
}

.cw-dashboard-common:after {
  content: '';
  background: #EBEEF4;
  height: 1px;
  position: absolute;
  bottom: 0;
}

.cw-dashboard-common.cw-dashboard-common_theme_main-chat {
  padding: 0 var(--cw-header-height);

  &:after {
    left: 56px;
    right: 56px;
  }
}

.cw-dashboard-common.cw-dashboard-common_theme_widget {
  padding: 0 18px;

  &:after {
    left: 0;
    right: 0;
  }
}

.cw-dashboard-common.cw-dashboard-common_theme_widget-favorite {
  height: 46px;

  &:after {
    left: 0;
    right: 0;
  }
}

.cw-dashboard-common.cw-dashboard-common_theme_widget-history {
  height: 46px;
  padding: 0 10px 0 16px;

  &:after {
    left: 0;
    right: 0;
  }
}

.cw-dashboard-common.cw-dashboard-common_theme_widget-search-msgs {
  height: 46px;
  padding: 0 10px 0 8px;

  &:after {
    left: 0;
    right: 0;
  }
}
