.items-on-page {
  display: flex;

  &__text {
    font-size: 12px;
    color: #767b92;
  }

  &__select {
    padding-left: 8px;
    padding-right: 8px;
    position: relative;
    user-select: none;
  }

  &__list {
    display: none;
    position: absolute;
    bottom: 20px;
    left: 0px;
    right: 0px;
    border-radius: 2px;
    box-shadow: 0 6px 10px 0px rgba(118, 123, 146, 0.2);
    border: 0.5px solid #c6cae3;
    background-color: #ffffff;
    z-index: 2;

    &_show {
      display: block;
    }
  }

  &__item {
    display: block;
    cursor: pointer;
    font-size: 12px;
    line-height: 1;
    color: #767b92;
    padding: 4px 8px;

    &:first-child {
      padding-top: 8px;
    }

    &:last-child {
      padding-bottom: 8px;
    }
  }

  &__selected {
    display: flex;
    align-items: center;
    cursor: pointer;

    &-text {
      font-size: 12px;
      font-weight: 500;
      color: #767b92;
    }

    &-caret {
      display: inline-block;
      width: 0;
      height: 0;
      margin-left: 4px;
      margin-bottom: 2px;
      vertical-align: middle;
      border-top: 3px dashed;
      border-top: 3px solid \9;
      border-right: 3px solid transparent;
      border-left: 3px solid transparent;
    }
  }
}
