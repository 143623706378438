.modal {
  width: 530px;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.loaderContainer {
  position: relative;
  min-height: 44px;
}

.flowLabel {
  color: var(--color-gray-800);
}

.noFlows {
  color: var(--color-gray-900);
}

.option {
  padding: 6px;
}
