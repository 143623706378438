.bar {
  position: sticky;
  top: 0;
  visibility: hidden;
  padding: 10px 0;
  display: flex;
  justify-content: center;
  pointer-events: none;
  z-index: 2;
}

:global(.cwm-scrollbox__date-bar_visible) .bar {
  visibility: visible;
}
