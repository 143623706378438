.link {
  color: var(--color-blue-500);
  text-decoration: none;
}

.link:visited {
  color: var(--color-blue-300);
}

.link:hover {
  color: var(--color-blue-700);
}
