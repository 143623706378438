.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: var(--indent-ml);
  padding-bottom: var(--indent-m);
}

.personalInfo {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: var(--indent-m);
}

.personalInfoTitle {
  color: var(--color-gray-800);
}

.corporateInfo {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: var(--indent-ml);
}

.corporateInfoTitle {
  color: var(--color-gray-800);
}

.divider {
  width: 100%;
  background-color: var(--color-gray-300);
  height: 1px;
}
