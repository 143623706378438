.accordion {
  display: flex;
  flex-direction: column;
  row-gap: var(--padding-s);
}

.createGroup {
  align-self: baseline;
}

.accordion .accordionTitle {
  background-color: var(--bg-light-blue);
  color: var(--color-gray-800);
}

.accordion .accordionDetails {
  display: flex;
  flex-direction: column;
  row-gap: var(--padding-xxs);
  padding-top: var(--padding-xxs);
}

.accordionGroupsDetails > *:not:first-child {
  padding: 0 var(--padding-xs);
}
