.table-item-export {
  position: relative

  &__title {
    cursor: pointer
    user-select: none

    img {
      display: inline-block;
    }
  }

  &__list {
    display: none
    position: absolute
    top: calc(100% + 6px)
    right: 0
    padding: 6px 0
    border-radius: 4px
    background-color: #1c50de
    z-index: 1;

    &_show {
      display: block
    }
  }

  &__item {
    width: 100%
  }

  &__icon {
    width: 13px
    margin-right: 20px
  }

  &__link {
    width: 100%
    display: inline-flex
    font-size: 12px
    font-weight: 500
    color: #b5c5f2
    padding: 7px 20px 7px 17px
    white-space: nowrap
    cursor: pointer

    &:hover {
      color: #fff
    }
  }

  &__el {
    cursor: pointer;
  }
}
