*,
*:before,
*:after {
  box-sizing: border-box
}

html {
  width: 100%
  height: 100%
  min-height: 100vh
  min-height: stretch
  overflow-y: hidden
  overflow-x: auto
  font-size: 16px
}

body {
  width: 100%
  height: 100%
}

.root {
  width: 100%
  height: 100%
}

.no-wrap-word {
  white-space: nowrap
}

.font-size-17 {
  font-size: 17px;
}
.Select-arrow-zone,.Select-option,.Select-control,.Select__dropdown-indicator{
  cursor:pointer;
}

#layers {
  z-index: 1301;
}
