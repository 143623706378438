.modalOpenButton {
  padding: 0;
  margin: 0 -4px;
  background-color: transparent;
  border: none;
  color: var(--color-blue-500);
}

.modalOpenButton:hover {
  text-decoration: underline;
}
