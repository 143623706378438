.rank {
  width: 100%;
  height: 100%;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.firstPlace {
  background-color: var(--secondary-green);
  color: var(--color-white);
}

.secondPlace {
  background-color: var(--secondary-yellow);
  color: var(--color-white);
}

.anotherPlace {
  background-color: var(--danger-color);
  color: var(--color-white);
}

.unknownPlace {
  background-color: var(--color-disabled);
  color: var(--color-white);
}
