.enclosure-dropdown-menu {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.enclosure-dropdown__title {
  display: inline-block;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  padding: 3px 6px;
  font-size: 14px;
  line-height: 1;
  border-radius: 4px;
  user-select: none;
}

.enclosure-dropdown-menu__list {
  display: block;
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 14px;
  text-align: left;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.18);
  z-index: 1000;
}

.enclosure-dropdown-menu__list .open {
  display: block;
}

.enclosure-dropdown-menu__list .close {
  display: none;
}
