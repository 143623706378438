.modal {
  width: 710px;
}

.content {
  color: var(--color-gray-900);
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.contentContainer {
  position: relative;
  min-height: 172px;
  max-height: 600px;
}

.detailsBlock {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.details {
  border: 1px solid #b2dfdb;
  border-radius: 4px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  height: fit-content;
}

.detailsCons {
  border-color: #f8bbd0;
}

.detailsHeader {
  display: flex;
  justify-content: space-between;
  color: var(--color-additional-lochinvar);
}

.detailsHeaderCons {
  color: var(--color-additionaly-cardinal);
}

.title {
  color: var(--color-additional-lochinvar);
}

.delimiter {
  height: 1px;
  width: 100%;
  border-bottom: 1px solid #b2dfdb;
}

.delimiterCons {
  border-bottom: 1px solid #f8bbd0;
}

.property {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.description {
  color: var(--color-gray-900);
}

.comments {
  color: var(--color-gray-700);
}
