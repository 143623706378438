.container {
  display: flex;
  flex-direction: column;
  gap: var(--indent-ml);
  padding-bottom: 12px;
}

.section {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: var(--indent-m);
}

.divider {
  width: 100%;
  background-color: var(--color-gray-300);
  height: 1px;
  margin-bottom: 12px;
}
