.wrapper {
  position: relative;
  width: 100%;
  border-radius: 2px;
}

.wrapper:hover {
  cursor: pointer;
  background: var(--color-gray-200);
}

.content {
  padding: var(--padding-xs);
  display: flex;
  flex-direction: row;
  gap: var(--padding-xs);
  align-items: center;
}

.active {
  outline: 1px solid var(--color-blue-500);
}

.badge {
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  width: 3px;
  background-color: var(--main-blue);
}

.avatar {
  max-width: 40px;
  max-height: 40px;
  min-width: 40px;
  min-height: 40px;
  background: rgb(18 18 18 / 3%);
  border-radius: 50%;
}

.avatar img {
  max-width: 40px;
  max-height: 40px;
  min-width: 40px;
  min-height: 40px;
  border-radius: 50%;
}

.avatarDisabled {
  opacity: 0.5;
}

.title {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: var(--color-gray-900);
}

.disabledText {
  color: var(--color-gray-600);
}

.rightBlock {
  margin-left: auto;
}

.disabledTextBlock {
  white-space: nowrap;
  text-transform: lowercase;
}

.permissionsContainer {
  padding: var(--indent-xxs) var(--indent-xs);
  display: flex;
  align-items: center;
  gap: var(--indent-xxs);
  margin-right: -2px;
}

.permissionsContainer:hover {
  background: var(--color-gray-200);
}

.alertIcon > path {
  stroke: var(--secondary-yellow);
}

.permissionText {
  color: var(--color-gray-800);
}
