.container {
  width: 172px;
  height: 172px;
  position: relative;
  z-index: 1;
  background: var(--color-blue-100);
  border-radius: var(--indent-xxs);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.containerWithImage:hover {
  background-color: var(--color-gray-900);
  opacity: 0.3;
}

.addImageBtnWithImage {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.containerWithImage:hover .addImageBtnWithImage {
  opacity: 1;
}

.addImageBtn {
  z-index: 30;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  cursor: pointer;
  width: 56px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: transparent;
  transition: background-color 0.3s ease;
}

.addImageIcon path {
  stroke: var(--color-blue-500);
}

.addImageBtn:hover {
  background-color: var(--color-white);
}

.addImageBtnWithImage {
  opacity: 0;
}

.canvas {
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
}

.canvasHover {
  background-color: var(--color-gray-900);
  opacity: 0.3;
}

.addBtnHover {
  opacity: 1;
  background-color: var(--color-white);
}

.img {
  position: relative;
  z-index: 5;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
