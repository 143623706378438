.table {
}

.row {
}

.header {
}

.rowColumn {
  margin: 0 !important;
}

.cell {
  flex: 1;
}
