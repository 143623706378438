.container {
  max-height: 650px;
  width: 530px;
}

.subtitle {
  color: var(--color-gray-800);
  padding: 0 32px;
  white-space: break-spaces;
}
