.container {
  height: 650px;
  width: 530px;
}

.content {
  height: 100%;
}

.header h2 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
}
