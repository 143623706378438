.iconWrapper {
  max-height: 24px;
  min-height: 24px;
  max-width: 24px;
  min-width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--color-white);
  box-shadow: 0 1px 2px rgb(40 39 50 / 18%);
  border-radius: 50%;
}
