.corporateInfo {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: var(--indent-ml);
}

.title {
  color: var(--color-gray-800);
}

.selectWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: var(--indent-xxs);
}

.selectLabel {
  color: var(--color-gray-800);
  display: flex;
  align-items: center;
}

.requiredChar {
  color: var(--color-red-error);
}

.divider {
  width: 100%;
  background-color: var(--color-gray-300);
  height: 1px;
}
