.viewEmployeesList {
  display: flex;
  flex-direction: column;
  gap: var(--indent-ml);
  height: 100%;
}

.noResult {
  padding: var(--indent-xs);
}

.noAssignedRole {
  color: var(--color-gray-900);
}

.list {
  height: 100%;
}
