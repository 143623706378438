.reliabilityMeter {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
}

.scale,
.meter {
  color: var(--color-gray-600);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.scale {
  display: flex;
  justify-content: space-between;
}

.scaleValue,
.scaleZero {
  width: 30px;
}

.scaleZero {
  text-align: center;
}

.meter {
  height: 16px;
  gap: 8px;
}

.zero {
  box-sizing: border-box;
  height: 22px;
  width: 1px;
  border-left: 1px solid var(--color-gray-500);
}

.pros,
.cons,
.prosFilled,
.consFilled {
  width: 100%;
  height: 16px;
  background-color: var(--color-gray-300);
  display: flex;
  align-items: flex-end;
  color: var(--color-white);
}

.pros,
.prosFilled {
  justify-content: flex-end;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.cons,
.consFilled {
  justify-content: flex-start;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.prosFilled,
.consFilled {
  height: 100%;
  min-width: fit-content;
}

.prosFilled {
  display: flex;
  justify-content: flex-start;
}

.consFilled {
  display: flex;
  justify-content: flex-end;
}

.prosFilled {
  background-color: var(--color-additional-lochinvar);
}

.consFilled {
  background-color: var(--color-additionaly-cardinal);
}

.score {
  padding: 0 4px;
}
