.cw-st-search-block {
  width: 100%;

  &__search {
    width: 300px;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #EBEEF4;
  }

  &__input {
    width: calc(100% - var(--cw-header-height));
    height: 36px;
    background-color: #ffffff;
    border: 0
    outline: none;

    &::placeholder {
      color: #c6cae3;
    }

    &_active {
      border-color: #767b92;
    }
  }

  &__btn {
    height: 36px;
    width: 36px;
    padding: 0;
    line-height: 0;
    border: none;
    background-color: transparent;
    display: none;
    top: 0;
    right: 0;
    opacity: 0.4;
    outline: none;
    justify-content: center;
    align-items: center;

    &_show {
      display: flex;
    }

    &_left {
      right: 26px;
    }

    &:hover {
      opacity: 1
    }
  }
}
