.container {
  display: grid;
  grid-template-columns: 1fr minmax(auto, 140px);
  grid-column-gap: var(--indent-xxs);
  border-bottom: 1px solid var(--color-gray-400);
  padding: var(--indent-xs);
  cursor: pointer;
}

.selectionHover:hover {
  background-color: var(--color-gray-200);
}

.selected {
  background-color: var(--color-gray-400);
}

.highlight {
  padding: 0;
  color: var(--color-blue-300);
  background-color: transparent;
  font-weight: var(--text-bold);
}
