.modal {
  height: fit-content;
}

.mainTextColor {
  color: var(--color-gray-900);
}

.lightTextColor {
  color: var(--color-gray-700);
}

.content {
  width: 530px;
  box-sizing: border-box;
  min-height: 74px;
  overflow: hidden;
  position: relative;
}

.comments {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 478px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

.comment {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
}

.name {
  align-items: center;
  display: flex;
  gap: 8px;
}

.author {
  max-width: 436px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.text {
  padding-right: 4px;
}
