.line {
  display: flex;
}

.description::before {
  content: '\00a0 - ';
}

.processed {
  color: var(--color-additionaly-lavender);
}

.delivered {
  color: var(--color-additionaly-grass);
}

.opened {
  color: var(--color-additionaly-orange);
}

.failed {
  color: var(--color-additionaly-cardinal);
}
