.container {
  display: flex;
  flex-direction: column;
  row-gap: var(--indent-s);
}

.preview {
  height: 220px;
  width: 390px;
  margin: 0 auto;
  position: relative;
}

.loader {
  background: transparent;
}

.video {
  height: 100%;
}

.actions {
  display: flex;
  justify-content: center;
  column-gap: var(--indent-s);
}

.hint {
  height: 17px;
}
