.userList {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 1px 15px 1px 0;
}

.userListContainer {
  max-height: 250px;
  height: 100%;
  overflow-x: hidden;
}
