.row {
  display: grid;
  grid-column-gap: 2px;
  grid-template-columns: 482px 111px;
}

.row:not(:last-child) .cellWrapper {
  padding-bottom: var(--indent-xxs, 4px);
}

.cell {
  padding: var(--indent-xs, 8px);
  height: 100%;
  background-color: var(--color-white, #fff);
  border: 1px solid var(--divider-color, #ebf0f9);
  border-radius: 2px;
}

.cell.company {
  display: grid;
  grid-template-columns: 351px 107px;
  grid-column-gap: var(--indent-xs, 8px);
}

.companyName {
  white-space: normal;
}

.chip {
  height: 23px;
}

.rowColumn {
  margin: 0;
}

.header {
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0 !important;
  padding: 0 var(--indent-xs, 8px);
  color: var(--main-dark-gray, #212229);
  background-color: var(--color-blue-50, #f3f6ff);
  border-radius: 2px;
}
