.contentContainer {
  display: flex;
  flex-direction: column;
  gap: var(--indent-ml);
}

.fileSection {
  background-color: var(--color-gray-200);
  border: 2px solid transparent;
  padding: 16px 0;
  overflow-y: scroll;
}

.dropzoneActive {
  background-color: var(--color-blue-50);
  border: 2px dashed var(--color-blue-500);
}
