.send {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: var(--cw-header-height);
  cursor: pointer;
}

.sendDisabled {
  cursor: not-allowed;
}

.sendImg {
  padding-bottom: var(--padding-xxs);
}

.editImg {
  padding-bottom: var(--padding-xxs);
  width: 25px;
  height: 25px;
}
