.infoBlock {
  margin-top: auto;
  display: flex;
  gap: 8px;
  padding: 8px;
  background: var(--color-blue-50);
  border-radius: 4px;
  border: 1px solid var(--color-gray-400);
}

.icon {
  flex-shrink: 0;
  background-color: var(--color-white);
}

.icon > path {
  stroke: var(--color-blue-300);
}

.text {
  color: var(--color-gray-800);
}
