.content {
  display: flex;
  flex-direction: column;
  gap: var(--indent-xs);
}

.attachment {
  padding: var(--indent-xxs) 0;
  width: 100%;
  border-top: 1px solid var(--color-gray-300);
  border-bottom: 1px solid var(--color-gray-300);
  display: flex;
  align-items: center;
  gap: var(--indent-xs);
}

.icon {
  width: 18px;
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
}

.name {
  width: 312px;
}

.size {
  color: var(--color-gray-800);
  width: 80px;
}
