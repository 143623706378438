.table-item-control {
  position: relative
  display: inline-block;

  &__title {
    cursor: pointer;
    padding: 0 5px;
    user-select: none;

    img {
      display: inline-block;
    }
  }

  &__list {
    display: none
    position: absolute
    right: 0
    padding: 6px 0
    border-radius: 4px
    background-color: #1c50de
    z-index: 6;

    &_show {
      display: block
    }

    &_position {
      &_top {
        bottom: calc(100% + 6px);
      }

      &_bottom {
        top: calc(100% + 6px);
      }
    }
  }

  &__item {
    width: 100%
    cursor: pointer;
  }

  &__icon {
    width: 13px
    margin-right: 20px
  }

  &__link {
    width: 100%
    display: inline-flex
    font-size: 12px
    font-weight: 500
    color: #b5c5f2
    padding: 7px 20px 7px 17px
    white-space: nowrap
    cursor: pointer

    &:hover {
      color: #fff
    }
  }

  &__disabled {
    width: 100%
    display: inline-flex
    font-size: 12px
    font-weight: 500
    color: #b5c5f2
    padding: 7px 20px 7px 17px
    white-space: nowrap;
    cursor: default;
  }
}
