.container {
  width: 530px;
}

.containerLoading {
  width: 530px;
  height: 580px;
}

.header {
  padding-bottom: 0;
  margin-bottom: -12px;
}
