.header {
  display: flex
  justify-content: space-between
  align-items: center
  height: 51px
  box-sizing: border-box
  flex-shrink: 0

  // TODO: removed when I change component ContextSidebar
  position: relative
  z-index: 2

  &__column {
    height: 100%

    &_left {
      width: 230px;
      //box-shadow: 4px 0 16px 0 rgba(36,78,171,0.05);
    }
  }

  &__logo {
    margin-left: 16px
    height: 100%
    display: flex
    align-items: center
  }

  &__controls {
    display: flex
    align-items: center
    height: 100%
  }

  &__button {
    display: flex
    align-items: center
    margin: 0 10px
    padding: 0 5px
    height: 100%

    &_company {
      cursor: pointer
    }

    &_account {
      margin-right: 0
    }

    &_logout {
      margin-left: 0
    }
  }
}

.chat-badge-all-count {
  margin-left: 10px
  margin-bottom: 7px
  background-color: #d9534f
}

.dialog-header-icon-link {
  font-size: 25px
  top: 5px
  text-decoration: none !important
}
.dialog-header-icon-link:focus {
  color: #337ab7 !important
}
