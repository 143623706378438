.container {
  background: var(--color-gray-100);
  box-shadow: 0 6px 10px rgb(118 123 146 / 20%);
  max-width: 530px;
}

.header {
  margin-bottom: 8px;
}

.title {
  color: var(--color-gray-900);
}

.content {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.section {
  background: var(--color-white);
  padding: 20px 16px;
  box-shadow: 4px 0 16px rgb(36 78 171 / 5%);
  border-radius: 4px;
}

.sectionTitle {
  margin-bottom: var(--padding-xxs);
  color: var(--color-gray-900);
}

.sectionContent {
  margin-bottom: var(--padding-m);
  color: var(--color-gray-800);
}

.disabledTitle {
  color: var(--color-gray-600);
}

.link {
  display: inline;
}

.link::before {
  content: ' ';
}

.info {
  padding: var(--indent-xs);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: var(--indent-xs);
  background-color: var(--color-blue-50);
}

.icon > path {
  stroke: var(--color-blue-300);
}
