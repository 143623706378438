.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 var(--padding-xs, 8px);
  column-gap: var(--padding-xs, 8px);
  height: 33px;
}

.content {
  display: flex;
  align-items: center;
  column-gap: var(--padding-xxs, 4px);
  color: var(--color-gray-800, #5b5f73);
  overflow: hidden;
}

.actions {
  visibility: hidden;
  display: flex;
  column-gap: var(--padding-s, 12px);
}

.row:hover {
  background-color: var(--hover-color, #f6f7fa);
}

.row:hover .actions {
  visibility: visible;
}
