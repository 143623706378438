.loader {
  max-height: 30px;
  min-height: 30px;
  max-width: 30px;
  min-width: 30px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
