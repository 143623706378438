/* TODO расскоментить когда будет время */

/* .iconContainer{ */

/*  max-height: 20px; */

/*  min-height: 20px; */

/*  max-width: 20px; */

/*  min-width: 20px; */

/*  display: flex; */

/*  justify-content: center; */

/*  align-items: center; */

/*  background: var(--color-white); */

/*  box-shadow: 0px 1px 2px rgba(40, 39, 50, 0.18); */

/*  border-radius: 50%; */

/* } */

/* .optionItem{ */

/*  display: flex; */

/*  align-items: center; */

/*  gap: var(--indent-xs); */

/* } */

/* .optionItemText{ */

/*  width: 100%; */

/* } */

/* .label{ */

/*  color:var(--color-gray-900) */

/* } */

/* .additionalLabel{ */

/*  color:var(--color-gray-400) */

/* } */

/* .optionItemSelected{ */

/*  background: var(--color-blue-50); */

/* } */

/* .optionItemSelectedIcon{ */

/*  fill: var(--color-blue-700); */

/* } */

.content {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
}

.option {
  display: flex;
  align-items: center;
  padding: var(--indent-xxs);
}

.option:hover {
  background: var(--color-blue-50);
  border-radius: var(--indent-xxs);
}

.optionContent {
  display: flex;
  gap: var(--indent-xs);
  align-items: center;
}

.selected {
  background: var(--color-blue-50);
  border-radius: var(--indent-xxs);
}

.selectOptionMark {
  margin-left: auto;
}

.selectOptionMark path {
  stroke: var(--color-blue-700);
}

.optionIcon {
  max-width: 20px;
  max-height: 20px;
  min-width: 20px;
  min-height: 20px;
}

.label {
  color: var(--color-gray-800);
  margin-bottom: var(--indent-xxs);
}
