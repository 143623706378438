.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 140px;
  max-height: 140px;
  background: var(--color-gray-200);
}

.loaderText {
  color: var(--color-gray-800);
}

.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  padding: 8px 60px 20px;
}

.viewText {
  color: var(--color-gray-900);
}

.title {
  text-overflow: ellipsis;
  padding: 8px 0;
  max-width: 458px;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  text-align: center;
}

.iconError path {
  fill: none;
  stroke: none;
}

.iconError path:nth-child(1) {
  stroke: var(--color-gray-600);
}

.iconError path:nth-child(2) {
  fill: var(--color-gray-600);
}

.iconError path:nth-child(n + 3) {
  fill: white;
}

.iconError rect {
  fill: var(--color-gray-600);
}

.textError {
  margin-top: var(--indent-xxs);
  color: var(--color-red-error);
  max-width: 340px;
}

.grayColorText {
  color: var(--color-gray-700);
}

.uploadButton {
  color: var(--color-blue-500);
  cursor: pointer;
}

.uploadIcon path {
  stroke: var(--color-blue-500);
}
