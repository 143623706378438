.dialog-list {
  .panel-body {
    display: block;
    background-color: #fff;
    height: 49px;
    border-radius: 4px;
  }

  .dialog-panel {
    cursor: pointer;
    box-shadow: none;
    border-radius: 4px;
    border: none;
    .panel-body {
      padding-top: 5px;
    }
  }

  .dialog-panel-unread {
    .panel-body {
        padding-top: 5px;
      }
  }

  .dialog-panel:hover {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  }
}

.dialogs-list-img {
  width: 38px;
  height: 38px;
  z-index: 1;
  background-color: #86b2d6;
  display: inline-block;
  object-fit: 'cover';
  margin-left: 5px;
}

.request-comment-img {
  width: 38px;
  height: 38px;
  z-index: 1;
  display: inline-block;
  margin-right: 15px;
}

.dialogs-list-color-char {
  display: inline-flex;
  width: 38px;
  height: 38px;
  margin-left: 5px;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 500;
  color: white;
}

.dialogs-list-color-label {
  display: inline;
  border-radius: 7px;
  text-transform: uppercase;
  color: #252B49;
  font-weight: 500;
  font-size: 12px;
  padding: 1px 7px;
  line-height: 1;
}

.dialog-list-extra-info {
  font-size: 12px;
  color: #252B49;
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  align-items: flex-end;
  height: 100%;
  white-space: nowrap;
  padding-right: 5px;
}

.dialog-list-extra-info-row {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: right;
}

.dialogs-list-row {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  width: 100%;
  padding-top: 5px;
  padding-bottom 5px;
}

.dialogs-list-row-bot {
  position: relative;
}

.dialogs-list-left {
  margin-right: auto;
  margin-left: 15px;
  position: relative;
  width: calc(65% - 20px);
}

.dialogs-list-left-info {
  display: flex;
  flex-flow: column;
  height: 20px;
  overflow: hidden;
}

.dialogs-list-company {
  font-size: 12px;
  color: #252b49;
  width: calc(25% - 21px);
  height 40px;
  margin-right: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align right;
  word-break: break-all;
}

.dialogs-list-time {
  margin-left: 10px;
  color: #888;
}

.dialogs-list-last-message {
  margin-right: auto;
  display: block;
  max-width: 100%
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  &-unread {
    background-color: #f3f4f8;
    border-radius: 4px;
    padding-left: 5px;
  }
}

.dialogs-list-info {
}

.dialogs-list-menu-button {
  width: 32px;
  padding-top: 3px;
  border-left: 0.5px solid #F2F4F8;
}

.dialog-panel-unread {
  .panel-body {
    background-color: rgba(74, 144, 226, 0.16)
  }
}

.dialogs-list-name {
  font-weight: 500;
  height: inherit;
}

.dialogs-list-name-block-extra {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.dialogs-list-name-extra {
  margin-left: 10px;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dialog-list-new-message {
  font-weight: 500;
  color: #1c50de
}

.dialog-message {
  width: 10px;
  height: 10px;
  border: 1px solid #fff;
  background-color: #fd4f53;
  border-radius: 50%;
  position: relative;
  top: -3px;
  right: 5px;
  opacity: 0;
  &-unread {
    opacity: 1;
    z-index: 1;
  }
  &-mute {
    background-color: #6A6F83;
  }
}
