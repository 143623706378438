.groupItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 var(--padding-xs, 8px);
  column-gap: var(--padding-xs, 8px);
  height: 33px;
}

.content {
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  column-gap: var(--padding-xxs, 4px);
  color: var(--color-gray-800, #5b5f73);
  position: relative;
}

.badgeNew {
  background-color: var(--color-red-error, #dd1e2a);
  width: 5px;
  height: 5px;
  border-radius: 50%;
  align-self: baseline;
  margin-top: 3px; /* Delete this when set correct line height and checkbox width */
}

.actions {
  visibility: hidden;
  display: flex;
  column-gap: var(--padding-s, 12px);
}

.groupItem:hover {
  background-color: var(--hover-color, #f6f7fa);
}

.groupItem:hover .actions {
  visibility: visible;
}
