.infoBlock {
  margin-top: auto;
  display: flex;
  gap: 8px;
  padding: 8px;
  background: var(--color-blue-50);
  border-radius: 4px;
}

.icon > path {
  flex-shrink: 0;
  stroke: var(--color-blue-300);
}

.text {
  color: var(--color-gray-900);
}

.link {
  margin-left: auto;
  color: var(--color-blue-500);
  cursor: pointer;
}
