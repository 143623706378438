.counter {
  position: absolute;
  top: -16px;
  right: 14px;
  color: #c6cae3;
  font-size: 9px;
  line-height: 11px;
  cursor: default;
}

.counterLimit {
  color: var(--color-red-error);
}
