.table-new {
  margin-bottom: 20px;

  table {
    table-layout: fixed;
    margin-bottom: 0;
  }

  &_fixed-columns {
    table {
      table-layout: fixed;
    }
  }

  &_scroll_auto {
    overflow: auto;
  }

  &_theme_permissions {
    .table-new__table-data {
      border-bottom: none !important;
    }

    .table-new__body {
      background-color: transparent;
    }
  }

  &__head {
    background-color: transparent;
  }

  &__body {
    background-color: #fff;
  }

  &__title {
    white-space: pre-line;
    padding: 2px 8px !important;
    line-height: 1.2 !important;
    height: 33px;
    border: none !important;
    color: #767b92;
    font-size: 12px;
    word-wrap: break-word;

    &_border {
      border-left: 1px solid #c6cae3 !important;
    }

    &_clickable {
      cursor: pointer;
      user-select: none;
    }

    &_checkbox {
      width: 28px;
      vertical-align: top !important;
    }
  }

  tr.info &__table-data {
    background-color: #f2f4f8 !important;
  }

  &__table-data {
    padding: 5px 8px !important;
    height: 33px;
    vertical-align: middle !important;
    color: #252b49;
    border-top: none !important;
    border-bottom: 1px solid #c6cae3;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &_border {
      border-left: 1px solid #c6cae3 !important;
    }

    &_centered {
      text-align: center;
    }

    &_no-ellipsis {
      overflow: visible;
      white-space: normal;
    }
    &_text-align_rigth {
      text-align: right;
    }
    &_text-align_center {
      text-align: center;
    }

    &_color_gray {
      color: #767B92;
    }
  }

  &__head > tr > .table-new__title:first-child {
    padding-left: 16px!important;
  }

  &__table-data:first-child {
    padding-left: 16px!important;
  }

  &__table-data:last-child {
    padding-right: 16px!important;
  }

  &__arrow {
    display: inline-block
    margin-left: 5px
    background-image: url('/img/icon_sort.svg')
    width: 14px
    height: 8px;
    opacity: 0

    &_flip {
      transform: rotate(180deg)
    }

    &_show {
      opacity: 1
    }
  }

  &__dnd {
    cursor: move;

    &_row {
      display: table;
      background-color: #FFFFFF;
    }

    &_handler {
      width: 30px;
      display: grid;
      justify-content: center;

      &_arrow {
        height: 10px;
        width: 10px;
        display: flex;
        justify-content: center;
        cursor: pointer;

        &_top {
          align-items: flex-end;
        }

        &_bot {
          align-items: flex-start;
        }
      }
    }
  }

  &__special-row {
    width: 100%;
    border-bottom: 1px solid #ebeef4;

    td {
      padding: 0!important;
    }
  }

  &__special-row:last-child {
    border-bottom: none;
  }
}


.table-new_theme_field {
  tr:hover {
    .table-new__table-data:first-child {
      border-left: 1px solid #C6CAE3;
    }
  }

  .table-new {
    &__body {
      background-color: transparent;
    }

    &__table-data {
      background-color: #fff;
      border-bottom: 3px solid #fafbfc;

      &:first-child {
        border-left: 1px solid #fff;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }

      &:last-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
  }
}

.table-new_theme_rows {
  .table {
    border-collapse: separate;
    border-spacing: 0 4px;
  }

  .table-new__title {
    height: 24px;
  }

  .table-new__table-data {
    border-top: 1px solid #E5E9F2!important;
  }

  td {
    border: 1px #E5E9F2;
    border-style: solid none;
    padding: 5px;

    &:first-child {
      border-left-style: solid;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    &:last-child {
      border-right-style: solid;
      border-bottom-right-radius: 5px;
      border-top-right-radius: 5px;
    }
  }

  a {
    color: #1C50DE;
  }
}

.table-new_theme_main {
  .table-new__head {
    border-bottom: 1px solid #EBEEF4;
  }

  .table-new__title {
    padding: 11px 8px!important;
    font-size: 14px;
    line-height: 1.5!important;
    font-weight: 400;
  }

  .table-new__table-data {
    height: 46px;
    font-size: 14px;
    line-height: 1.5;
    border-bottom: 1px solid #EBEEF4!important;
    position: relative;
  }

  .status-text {
    color: #767B92;
  }

  .table-new__table-row {
    cursor: pointer;
  }

  .table-new__table-row-gray {
    background-color: #fafbfc;
  }

  .table-new__table-row:hover {
    background-color: rgba(242, 244, 248, 0.5);
    transition: .2ms
  }

  a {
    color: #1C50DE;
  }
}

.table-new_theme_wild_rows {
  .table-new__table-data {
    padding-top: 12px!important;
    padding-bottom: 12px!important;
    line-height: 1.5;
  }

  .reow-test {
    .table-new__table-data {
      background-color #00CEE0;
    }
  }

  .reow-test:hover {
    .table-new__table-data {
      background-color #1c50de;
    }
  }
}
