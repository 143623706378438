.disabled {
  opacity: 0.5;
}

.good {
  color: var(--color-secondary-green);
}

.pricy {
  color: var(--color-additionaly-orange);
}

.overpriced {
  color: var(--color-additionaly-cardinal);
}
