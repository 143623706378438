.grid-page_theme_default {
  height: 100%
  width: 100%
  display: flex
  flex-direction: column
  position: relative;

  .grid-page__header {
    height: 51px
    width: 100%
  }

  .grid-page__main {
    display: flex
    height: 100%
    overflow: hidden;
  }

  .grid-page__navbar {
    display: flex;
    flex-direction: column;
    flex-shrink: 0
    background-color: #ffffff
    box-shadow: 4px 0 16px 0 rgba(36, 78, 171, 0.05)
    position: relative
    z-index: 99
  }

  .grid-page__content {
    width: calc(100% - 230px)
  }
}
