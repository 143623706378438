.headerTextColor {
  margin-left: var(--padding-xxs);
  margin-right: auto;
  color: var(--color-gray-900);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.selectedHeader {
  background: var(--color-blue-50);
}

.headerContainer {
  padding: 0 var(--padding-xxs);
  display: flex;
  flex-direction: row;
  align-items: center;
}

.closeBtn {
  margin-left: 10px;
}

.filterOptionItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--padding-xs);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.filterOptionList {
  background: var(--color-white);
  box-shadow: 0 3px 7px rgb(36 48 103 / 20%);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: var(--padding-m);
  gap: 10px;
  margin: 0;
}

.labelContainer {
  margin-bottom: 0;
  display: flex;
  gap: 8px;
  align-items: flex-start;
}

.label {
  color: var(--color-black);
  cursor: pointer;
}
