.loader {
  display: flex;
  justify-content: center;

  &_page {
    padding-top: 70px;
  }

  &_intext {
    display: inline-flex;
    height: 100%;
  }

  &_center {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}

.loader-dots-wrap {
  /* display: table-cell; */
  /* vertical-align: middle; */
}

.loader-dots {
  display: flex;
  justify-content: center;
}

.loader-dot {
  margin: 2px;
  width: 7px;
  height: 7px;

  border-radius: 50%;
  background: #767b92;
  transform: scale(0.5);
  animation: scale-dot 1.2s ease-out infinite;

  &:nth-of-type(2) {
    animation-delay: 0.2s;
  }
  &:nth-of-type(3) {
    animation-delay: 0.3s;
  }
  &:nth-of-type(4) {
    animation-delay: 0.4s;
  }
  &:nth-of-type(5) {
    animation-delay: 0.5s;
  }
}

@keyframes scale-dot {
  0% {
    transform: scale(0.5);
    opacity: 0.5;
  }
  25% {
    transform: scale(1);
    opacity: 1;
  }
  75% {
    transform: scale(0.5);
    opacity: 0.5;
  }
  100% {
    transform: scale(0.5);
    opacity: 0.5;
  }
}

.loader-with-message {
  &__container {
    display: flex;
    align-items: center;
  }
  &__text {
    font-weight: 400;
    font-size: 14px;
    margin-left: 10px;

    &-gray {
      color: #767B92;
    }

    &-black {
      color: #000000;
    }
  }
}

.section-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.loader-dot_intext-container {
  width: 12px;
  justify-content: flex-start;
}

.loader-dot_intext {
  overflow: hidden;
  color: var(--color-gray-700);
  animation: ellipsis 0.9s steps(4, end) infinite;
  width: 0;
  margin-left: 2px;
}

@keyframes ellipsis {
  to {
    width: 100%;
  }
}
