.checkbox-new {
  width: 12px;
  height: 12px;
  display: inline-flex;
  border-radius: 4px;
  border: 1px solid #c6cae3;
  cursor: pointer;
  margin: 0;
  margin-right: 6px;
  position: relative;
  flex-shrink: 0;
  user-select: none;

  &_read_only {
    cursor: default;
  }

  &_margin-right-0 {
    margin-right: 0;
  }

  &__img {
    opacity: 0;
    width: 100%;
    height: 100%;
    padding-bottom: 1px;
  }

  &_set {
    background-color: #767b92;
    border-color: #767b92;

    img {
      opacity: 1;
    }
  }
}
