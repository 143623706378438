.container {
  width: 600px;
  max-height: calc(100vh - 80px);
}

.containerLoading {
  width: 600px;
  height: 800px;
  max-height: calc(100vh - 80px);
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
