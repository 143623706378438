.container {
  width: 100px;
  height: 100px;
  position: relative;
  border-radius: 4px;
  background-color: var(--color-blue-100);
  overflow: hidden;
  flex-shrink: 0;
}

.container::after {
  content: '+';
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  font-size: var(--h1-line-height);
  transform: translate(-50%, -54%);
  color: var(--color-blue-500);
}

.label {
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: relative;
}

.label::before {
  content: '';
  position: absolute;
  z-index: 3;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  width: 33%;
  height: 33%;
  border-radius: 9999px;
  background-color: var(--color-blue-50);
}

.label::after {
  content: '+';
  position: absolute;
  z-index: 4;
  top: 50%;
  left: 50%;
  opacity: 0;
  font-size: var(--h1-line-height);
  transform: translate(-50%, -54%);
  color: var(--color-blue-500);
}

.label:hover::before,
.label:hover::after {
  opacity: 1;
}

.input {
  display: none !important;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
  z-index: 2;
}
