.wrapper {
  position: absolute;
  top: -52px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-xs) var(--padding-xxs);
  background: var(--color-white);
  border-radius: var(--padding-xxs);
  gap: var(--padding-xxs);
  filter: drop-shadow(0 3px 7px rgb(36 48 103 / 20%));
  right: 69px;
}

.wrapper::after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-width: 10px;
  border-style: solid;
  border-color: #fff transparent transparent;
  top: 44px;
  left: 50%;
  transform: translateX(-50%);
}

.verticleLine {
  width: 1px;
  background: var(--color-gray-300);
}

.container {
  position: relative;
}
