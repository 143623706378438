.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--indent-m);
  cursor: default;
}

.header {
  color: var(--color-gray-600);
}
