/* reset styles */
:global(.product-table) .inputContainer textarea {
  margin: 0 !important;
  min-height: 15px !important;
  border: none !important;
  line-height: 17px;
  padding: 8.5px 12px;
  box-sizing: unset;
}

.inputContainer .input {
  border-radius: 2px;
}

.inputContainer.withBorder .input {
  border-color: #f2f4f8;
}

.fullSpace {
  height: 100%;
  width: 100%;
}

.noBorder {
  border: none !important;
}

.noPadding {
  padding: 0 !important;
}

.analog {
  display: flex;
  align-items: center;
  width: 100%;
  column-gap: var(--indent-xs);
}

.adornment {
  margin-left: auto;
}

.analogAdornment {
  padding: 0 8px;
}

.hint.show * {
  border: none !important;
}

.needName {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 6px var(--indent-m);
  background-color: var(--color-white);
  border: 1px solid var(--color-gray-300);
  border-radius: 2px;
}
