.container {
  width: 100%;
  margin-bottom: var(--indent-xxxs);
  padding: var(--indent-s) var(--indent-xs) var(--indent-s) var(--indent-m);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--indent-xs);
  border-radius: 2px;
  background-color: var(--color-white);
  box-shadow: 0 1px 2px rgb(40 39 50 / 18%);
  cursor: default;
}

.link {
  flex: 1 1 100%;
  padding-right: var(--indent-xs);
  border-right: 1px solid var(--color-gray-400);
}
