.permission {
  display: flex;
  width: 100%;
  justify-content: space-between;
  color: var(--color-gray-900);
}

.leftSideContainer {
  display: flex;
  flex-direction: column;
  gap: var(--indent-xxs);
  max-width: 470px;
}

.disabledLabel {
  opacity: 0.5;
}

.description {
  color: var(--color-gray-700);
}

.disabledDesc {
  opacity: 0.5;
}
