.items {
  display: flex;
}

.text {
  font-size: 12px;
  color: #767b92;
}

.select {
  padding-left: 8px;
  padding-right: 8px;
  position: relative;
  user-select: none;
}

.list {
  display: none;
  position: absolute;
  left: 0;
  right: 0;
  border-radius: 2px;
  box-shadow: 0 6px 10px 0 rgb(118 123 146 / 20%);
  border: 0.5px solid #c6cae3;
  background-color: #fff;
  z-index: 2;
}

.listShow {
  display: block;
}

.item {
  display: block;
  cursor: pointer;
  font-size: 12px;
  line-height: 1;
  color: #767b92;
  padding: 4px 8px;
  text-align: center;
}

.item:first-child {
  padding-top: 8px;
}

.item:last-child {
  padding-bottom: 8px;
}

.selected {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.selectedText {
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  color: #767b92;
  cursor: pointer;
  text-align: center;
  padding: 0 5px;
  user-select: none;
}

.selectedText:hover {
  color: #23527c;
}
