.dateContainer {
  display: flex;
  gap: var(--indent-xs);
  margin-bottom: var(--indent-ml);
}

.datePicker {
  width: 180px;
  height: 34px;
}

.divider {
  position: relative;
  top: var(--indent-l);
}

.quickSelect {
  color: var(--color-gray-800);
  margin-bottom: var(--indent-s);
}

.buttonsContainer {
  display: flex;
  gap: var(--indent-xs);
  margin-bottom: var(--indent-ml);
}

.button,
.button:hover,
.button:active,
.button:focus {
  width: 160px;
  padding: var(--indent-xs) var(--indent-m);
  color: var(--color-black) !important;
  border: 1px solid var(--color-gray-500);
  border-radius: var(--indent-xxs);
  background-color: var(--color-white) !important;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: var(--indent-m);
}
