.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: hidden;
}

.emailLabel {
  color: var(--color-gray-800);
  margin-bottom: 4px;
}

.emailError {
  font-size: 10px;
  color: var(--color-red-error);
  margin-top: 2px;
  margin-bottom: 5px;
}

.hideError {
  visibility: hidden;
}

.content .hidden {
  opacity: 0;
  max-height: 0;
  transition: max-height 0.3s ease-out;
  overflow: hidden;
}

.content .expanded {
  opacity: 1;
  margin: 0;
  max-height: 100px;
  transition: max-height 0.3s ease-in;
}

.messageBlock {
  padding: 8px;
  background-color: var(--color-blue-50);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 8px;
}

.iconContainer {
  width: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon path {
  stroke: var(--color-blue-300);
}

.userCard {
  padding: 12px 16px;
  border-bottom: 1px solid var(--color-gray-300);
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}

.userInfo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
}

.avatar {
  border-radius: 50%;
}

.userContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  width: 344px;
}

.name {
  color: var(--color-gray-900);
}

.company {
  color: var(--color-gray-700);
}

.link {
  display: inline;
}
