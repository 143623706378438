.modal {
  width: 530px;
}

.content {
  display: flex;
  flex-direction: column;
  gap: var(--indent-s);
}

.content p:not(:last-child) {
  margin-bottom: var(--indent-s);
}

.content p:last-child {
  margin-bottom: 0;
}

.raw {
  display: flex;
  gap: 2px;
}

.cell {
  padding: var(--indent-xxs) var(--indent-xs);
  border: 1px solid var(--color-gray-300);
  border-radius: 2px;
}

.range {
  width: 144px;
}

.commission {
  width: 320px;
}

.title {
  background-color: var(--color-alpha-blue-50);
}

.table {
  display: flex;
  flex-direction: column;
  gap: 2px;
}
