.profile-menu {
  position: relative
  z-index: 1051

  &_theme {
    &_blue {
      .profile-menu__list {
        background-color: #1c50de  
      }

      .profile-menu__link {
        color: #b5c5f2
        font-weight: 500
      }

      .profile-menu__link:hover {
        color: #fff
      }

      .profile-menu__icon {
        width: 13px
        margin-right: 20px
      }
    }

    &_white {
      .profile-menu__list {
        background: #FFF
        box-shadow: 0px 6px 10px rgba(118, 123, 146, 0.2)
      }

      .profile-menu__link {
        color: #767B92
        padding: 7px 50px 7px 20px
        white-space: nowrap
      }

      .profile-menu__icon {
        width: 13px
        margin-right: 10px
      }

      .profile-menu__last-button {
        background: #FAFBFC
        text-align: center
        color: #767B92
        display: block
        margin-bottom: -6px
        padding: 6px 10px 12px;
      }
    }
  }

  &__title {
    font-size: 14px
    font-weight: 500
    color: #767b92
    cursor: pointer
    padding: 0 5px
    user-select: none
    display: flex
  }


  &__title-icon {
    margin-left: 6px
  }

  &__list {
    display: none
    position: absolute
    top: calc(100% + 6px)
    right: 0
    padding: 6px 0
    border-radius: 4px
    overflow: hidden

    &_show {
      display: block
    }
  }

  &__item {
    width: 100%
  }

  &__link {
    width: 100%
    display: inline-flex
    padding: 7px 20px 7px 17px
    background-color: transparent;
    border: 0;
  }
}
