.row {
  display: grid;
  grid-template-columns: 144px minmax(220px, 1fr);
  gap: var(--indent-xxxs);
}

.cell {
  padding: var(--indent-xxs) var(--indent-xs);
  border: 1px solid var(--color-gray-300);
  border-radius: 2px;
}

.title {
  background-color: var(--color-alpha-blue-50);
}

.table {
  display: flex;
  flex-direction: column;
  gap: var(--indent-xxxs);
}
