.header_theme_one {
  background-color: #fff
  border-bottom: 1px solid #f2f4f8
  z-index: 1000;
  height: 54px;

  .header__button {
    font-size: 14px
    color: #808080
    text-decoration: none

    &_company:hover {
      color: #333
    }
  }
}
