.primaryTextColor {
  color: var(--color-gray-900);
}

.secondaryTextColor {
  color: var(--color-gray-800);
}

.infoBlock {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: var(--indent-xs);
}

.column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: var(--indent-m);
}

.row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.avatar {
  border-radius: 5px;
  object-fit: cover;
}

.delimiter {
  width: 100%;
  height: 0;
  border-bottom: 1px solid var(--color-gray-300);
}

.columnRight {
  max-width: 172px;
  width: 100%;
}
