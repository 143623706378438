.title {
  color: var(--color-gray-800);
}

.field {
  margin-bottom: var(--indent-ml);
}

.select {
  border: 1px solid var(--color-gray-500);
  border-radius: var(--indent-xxs);
}

.priceContainer {
  width: 100%;
  display: flex;
  align-items: center;
  gap: var(--indent-xxs);
}

.priceInput {
  flex: 1 1 50%;
  height: var(--indent-xl);
  padding-left: var(--indent-xxs);
}

.menu {
  height: 120px;
}

.priceInput::placeholder,
.categoryInput::placeholder {
  color: var(--color-gray-500);
}

.actions {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.submit {
  margin-left: var(--indent-m);
}
