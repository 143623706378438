.status {
  display: flex;
  flex-direction: row;
  gap: var(--indent-xs);
  align-items: center;
}

.statusChar {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.statusText {
  color: var(--color-gray-800);
}

.statusChar.ready {
  background: var(--color-blue-400);
}

.statusChar.inProgress {
  background: var(--color-blue-800);
}

.statusChar.done {
  background: var(--color-secondary-green);
}

.statusChar.cancelled {
  background: var(--color-red-error);
}
