.content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.switcherContainer {
  display: flex;
  gap: 8px;
}

.switchers {
  padding-bottom: 16px;
}

.subtitle {
  color: var(--color-gray-800);
  margin-bottom: 12px;
}

.timePickers {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 0 var(--indent-xs);
}

.timePicker {
  width: 100%;
}

.dataPicker {
  width: 100%;
}
