.modalContent {
  display: flex;
  flex-direction: column;
  gap: 12px;
  position: relative;
}

.list {
  width: 100%;
  height: 430px;
}

.noResult {
  color: var(--color-gray-700);
}
