.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 101px;
}

.text {
  margin-top: 56px;
}

.refresh {
  margin: 10px 0 56px;
}

.robotImgContainer {
  margin-top: 14px;
}

.robotImgContainer img {
  width: 300px;
}
