.container {
  width: 100%;
  height: 100%;
  background-color: var(--color-gray-100);
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.title {
  flex-shrink: 0;
}

.content {
  flex-grow: 1;
  max-height: calc(100% - 82px);
}

.actions {
  margin: 0 auto;
  display: flex;
  column-gap: 20px;
}

.submitButton path {
  fill: var(--color-white);
}

.submitButton .loader {
  position: unset;
  background-color: var(--main-blue);
}

.submitButton .loader div {
  background-color: var(--color-white);
}
