.typeahead {
  border-color: var(--color-gray-500);
}

.typeahead::placeholder {
  color: var(--color-gray-600);
}

.option {
  padding: 5px 8px;
  cursor: pointer;
  white-space: break-spaces;
  color: var(--color-gray-800);
}

.label {
  display: flex;
  flex-direction: column;
  gap: 4px;
  color: var(--color-gray-800);
}

.required::after {
  content: ' *';
  color: var(--color-red-error);
}

.companyInputGroup {
  display: flex;
  flex-direction: column;
  color: var(--color-red-error);
}
