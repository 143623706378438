.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: inherit;
  width: 100%;
  background: var(--color-gray-200);
}

.loaderText {
  color: var(--color-gray-800);
}

.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
}

.viewText {
  color: var(--color-gray-900);
}

.title {
  text-overflow: ellipsis;
  padding: 8px 0;
  max-width: 458px;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  text-align: center;
}

.iconError path {
  fill: none;
  stroke: none;
}

.iconError path:nth-child(1) {
  stroke: var(--color-gray-600);
}

.iconError path:nth-child(2) {
  fill: var(--color-gray-600);
}

.iconError path:nth-child(n + 3) {
  fill: white;
}

.iconError rect {
  fill: var(--color-gray-600);
}

.textError {
  text-align: center;
  color: var(--color-red-error);
}

.choseFileBlue {
  color: var(--color-blue-500);
  cursor: pointer;
}

.choseFileGray,
.choseFileBlue {
  cursor: pointer;
}

.choseFileGray {
  color: var(--color-blue-500);
}

.choseFileGray {
  color: var(--color-gray-800);
}
