.titleCounter {
  display: flex;
  justify-content: right;
  align-items: center;
}

.currentCount {
  color: var(--main-blue, #1c50de);
}

.totalCount {
  color: var(--main-dark-gray, #212229);
}
