.pagination-new {
  display: inline-flex
  justify-content: center
  align-items: center

  &__nav-text {
    font-size: 12px
    color: #404560
    margin: 0 23px
  }

  &__btn {
    border: none
    background-color: transparent
    outline: none
    padding: 0
    display: flex
    justify-content: center
    align-items: center

    &:not(&_disabled):hover {
      path {
        fill: #6a6f83
      }
    }

    &_disabled {
      cursor: default

      path {
        fill: #c6cae3
      }
    }

    &_first {
      margin-right: 15px
    }

    &_last {
      margin-left: 15px
    }
  }
}
