.downloadContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--indent-xxxs);
}

.downloadButton {
  color: var(--color-blue-500);
  cursor: pointer;
}

.downloadIcon path {
  stroke: var(--color-blue-500);
}
