.phoneNumberWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 172px;
}

.phoneNumberLabel {
  color: var(--color-gray-800);
  display: flex;
  align-items: center;
}

.requiredChar {
  margin-left: 2px;
  margin-top: -4px;
  color: var(--color-red-error);
}

.errorMessage {
  color: var(--color-red-error);
}

.input {
  box-sizing: border-box;
  width: 100%;
  padding: 0 var(--indent-m, 16px);
  background-color: var(--color-white);
  border: 1px solid var(--color-gray-500);
  border-radius: 4px;
  height: 34px;
  color: var(--color-gray-900);
  outline: none;
}

.input.isError {
  border-color: var(--color-red-error);
}

.input::placeholder {
  color: var(--color-gray-600);
}

.input:disabled {
  border-color: var(--color-blue-200);
  background: inherit;
}

.input:hover {
  border-color: var(--color-blue-200);
}

.input:focus {
  border: 1px solid var(--color-blue-500);
  box-shadow: 0 0 5px rgb(35 105 241 / 31%);
}

.error {
  border: 1px solid var(--color-red-error);
  border-radius: 4px;
  box-shadow: 0 0 5px rgb(35 105 241 / 31%);
}
