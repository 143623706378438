.grid-content_theme_default {
  height: 100%
  width: 100%
  display: flex
  flex-direction: column
  background-color: #fafbfc

  .grid-content__panel {
    width: 100%
    height: 56px
    position: relative
  }

  .grid-content__main {
    height: calc(100vh - 56px - 51px); // 56px - grid-content__panel, 51px - header
    display: flex;
  }

  .grid-content__content {
    width: 100%
    height: 100%
    overflow-x: auto

    &_theme_default {
      .grid-content__content-inner {
        padding: 40px
      }
    }

    &_theme_customers {
      .grid-content__content-inner {
        min-height: 500px;
        padding: 30px 0px 15px 15px
      }
    }
  }

  .grid-content__content-inner {
    width: 100%
    /* min-width: 900px */
    //overflow: hidden
    min-height: 100%
    position: relative
  }

  .grid-content__sidebar {
    height: 100%
    flex-shrink: 0

    & > div {
      border-top: 1px solid #f2f4f8
    }
  }
}

.sidebar-is-open #alertMessage {
  right: 322px !important
}

.sidebar-is-close #alertMessage {
  right: 28px !important
}
